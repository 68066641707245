// 404.js
import Link from 'next/link'

export default function FourOhFour() {
  return (
    <div className="w-full mx-auto text-shadow text-center drop-shadow-lg">
      <h1 className="text-shadow-md text-3xl md:text-5xl">
        404 - Page Not Found
      </h1>
      <Link href="/">
        <a>Go back home</a>
      </Link>
    </div>
  )
}
